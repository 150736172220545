export default {
  en: {
    shortFormat: {
      dateStyle: "short"
    }
  },
  hr: {
    shortFormat: {
      dateStyle: "short"
    }
  }
};

<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>{{ t("downloadApp.title") }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Area -->
  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>{{ t("downloadApp.downloadFromAppStore") }}</p>
            <a
              href="https://apps.apple.com/ch/app/llama-points/id1589949105?platform=iphone"
            >
              <img
                class="app-store-badge"
                src="/images/app-store-badge.png"
                alt="Download from the Apple App Store"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>{{ t("downloadApp.downloadFromGooglePlay") }}</p>

            <a
              href="https://play.google.com/store/apps/details?id=com.bfmsolutions.LlamaPoints"
            >
              <img
                class="play-store-badge"
                src="/images/google-play-badge.png"
                alt="Download from the Google Play Store"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "DownloadApp",
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
  data() {
    return {
      info: null,
    };
  },
  methods: {
    shouldRedirect() {
      if (this.$route.query.redirect=="true") {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAndroid() {
      if (/Android/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    isiPhone() {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.shouldRedirect() && this.isiPhone()) {
      window.location.href =
        "https://apps.apple.com/ch/app/llama-points/id1589949105?platform=iphone";
    }
    if (this.shouldRedirect() && this.isAndroid()) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.bfmsolutions.LlamaPoints";
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <LLMenu />
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
  <LLFooter />
</template>

<script>
import LLFooter from "@/components/Footer.vue";
import LLMenu from "@/components/Menu.vue";

export default {
  name: "App",
  components: { LLMenu, LLFooter },
};
</script>

<style>
@import "./assets/css/global.css";
</style>

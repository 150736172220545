<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-10">
          <div class="section-heading-14 text-center">
            <h3>Impressum</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content Area -->
  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>
              <strong>Firma</strong> - BFM Solutions GmbH, (CHE-460.502.879)<br />
              <strong>Address</strong> - Neuheimstr 34b, CH-8853 Lachen. Switzerland<br/>
              <strong>Geschäftsführer</strong> - Fergus Rae
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "llama-impressum",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
    <!-- QR Code Area -->
    <div class="inner-terms-banner">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-10">
                    <div class="section-heading-14 text-center">
                        <h2>Llama Points</h2>
                        <h2>
                            Digital Loyalty app</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Area -->
    <div class="terms-condition-area">
        <div class="container">
            <div class="row justify-content-center">
                <LlamaQrCode/>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import LlamaQrCode from "@/components/QrCode.vue";

export default defineComponent({
    name: "QrCodePage",
    components: {  LlamaQrCode },

});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<template>
  <!-- Pricing  Area -->
  <div class="inner-service-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-10 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>{{ t("pricing.title") }}</h2>
            <p>{{ t("pricing.subTitle") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Currency Selector -->
  <div class="currency-selector text-center hidden">
    <label for="currency">{{ t("pricing.selectCurrency") }}: </label>
    <select id="currency" v-model="selectedCurrency">
      <option value="BLANK">BLANK</option>
      <option value="GBP">GBP</option>
      <option value="EUR">EUR</option>
      <option value="CHF">CHF</option>
      <option value="USD">USD</option>
    </select>
  </div>

  <!-- Table -->
  <div class="inner-pricing-area-1">
    <div class="container">
      <div class="row justify-content-center inner-pricing-area-1-items">
        <!-- Plan 1 Llama Luv -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan1name") }}</h4>
                <span>{{ t("pricing.plan1subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>{{ formatPrice(prices.plan1) }}</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan1feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan1feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ formatMessagePrice(prices.messagePrice) }}
                  {{ t("pricing.plan1feature3") }}
                  <font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan1feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan1feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li class="del">
                  {{ t("pricing.plan1feature6")
                  }}<font-awesome-icon :icon="['fas', 'times']" />
                </li>
                <!-- Feature 7 -->
                <li class="del">
                  {{ t("pricing.plan1feature7")
                  }}<font-awesome-icon :icon="['fas', 'times']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
        <!-- Plan 2 - Llama Tastic -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card professional-price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan2name") }}</h4>
                <span>{{ t("pricing.plan2subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>{{ formatPrice(prices.plan2) }}</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan2feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan2feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ t("pricing.plan2feature3") }}
                  {{ formatMessagePrice(prices.messagePrice) }}
                  <font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan2feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan2feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li>
                  {{ t("pricing.plan2feature6")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 7 -->
                <li>
                  {{ t("pricing.plan2feature7")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
        <!-- Plan 3 - Llama Max -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan3name") }}</h4>
                <span>{{ t("pricing.plan3subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>{{ formatPrice(prices.plan3) }}</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan3feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan3feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ t("pricing.plan3feature3")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan3feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan3feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li>
                  {{ t("pricing.plan3feature6") }}
                  <font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 7 -->
                <li>
                  {{ t("pricing.plan3feature7")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, onMounted } from "vue";

export default {
  name: "PricingTable",
  setup() {
    const { t, locale } = useI18n();
    const selectedCurrency = ref("BLANK");

    const prices = reactive({
      plan1: 19, // Default EUR price
      plan2: 79, // Default EUR price
      plan3: 199, // Default EUR price
      messagePrice: 1, // Default EUR price
    });

    const messagePrices = reactive({
      plan1: 1, // Default EUR price
      plan2: 1, // Default EUR price
      plan3: 1, // Default EUR price
    });

    const currencyRates = {
      BLANK: { plan1: 19, plan2: 79, plan3: 199, messagePrice: 1 },
      EUR: { plan1: 19, plan2: 79, plan3: 199, messagePrice: 1 },
      GBP: { plan1: 19, plan2: 79, plan3: 199, messagePrice: 1 },
      CHF: { plan1: 19, plan2: 79, plan3: 199, messagePrice: 1 },
      USD: { plan1: 19, plan2: 79, plan3: 199, messagePrice: 1 },
    };

    watch(
      () => selectedCurrency.value,
      (newCurrency) => {
        prices.plan1 = currencyRates[newCurrency].plan1;
        prices.plan2 = currencyRates[newCurrency].plan2;
        prices.plan3 = currencyRates[newCurrency].plan3;
        messagePrices.plan1 = currencyRates[newCurrency].plan1;
        messagePrices.plan2 = currencyRates[newCurrency].plan2;
        messagePrices.plan3 = currencyRates[newCurrency].plan3;
      }
    );

    const formatPrice = (price) => {
      const currencySymbols = {
        BLANK: "",
        EUR: "€",
        GBP: "£",
        CHF: "CHF",
        USD: "$",
      };
      return `${currencySymbols[selectedCurrency.value]} ${price}`;
    };

    const formatMessagePrice = (messagePrice) => {
      const currencySymbols = {
        BLANK: "",
        EUR: "€",
        GBP: "£",
        CHF: "CHF",
        USD: "$",
      };
      return `${currencySymbols[selectedCurrency.value]} ${messagePrice}`;
    };

    const determineCurrency = (country) => {
      const euCountries = [
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
      ];

      if (country === "GB") {
        selectedCurrency.value = "GBP";
      } else if (country === "CH") {
        selectedCurrency.value = "CHF";
      } else if (euCountries.includes(country)) {
        selectedCurrency.value = "EUR";
      } else {
        selectedCurrency.value = "USD";
      }
    };

    const fetchCountryFromIP = async () => {
      try {
        // Fetch user's IP address from a public service
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const userIp = ipData.ip;

        // Log the IP to the console for debugging
        console.log("User IP: ", userIp);

        // Call your API to get country code (use back ticks for string interpolation)
        const response = await fetch(`/api/iplookup?ip=${userIp}`);
        const data = await response.json();

        // Log to console for debugging
        console.log("Country from IP: ", data.country);

        determineCurrency(data.country);
      } catch (error) {
        console.error("Error fetching country from IP: ", error);
      }
    };

    onMounted(() => {
      fetchCountryFromIP();
    });

    return {
      locale,
      t,
      selectedCurrency,
      prices,
      messagePrices,
      formatPrice,
      formatMessagePrice,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hidden {
  display: none;
}
.currency-selector {
  margin-bottom: 20px;
}
</style>

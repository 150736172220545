<template>
 <!-- Footer Area -->
 <footer class="footer-l10">
  <SocialMedia />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-border-top"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-sm-9">
          <div class="row">
            <div class="col-xl-9 col-lg-10 col-md-8">
              <!-- <a href="#"><img src="./images/logos/logo-paste.png" alt="" class="footer-logo"></a> -->
              <div class="content">
                <p>Copyright © BFM Solutions GmbH 2021</p>
              </div>
              <div class="social-icons">
                <ul class="pl-0 list-unstyled d-flex align-items-end">
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-google"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="row">
            <div class="col-lg-5 col-md-3 col-sm-4 col-xs-6">
              <div class="footer-widget">
                <p class="widget-title">{{ t("footer.contactUs") }}</p>
                <ul class="widget-links pl-0 list-unstyled">
                  <p>
                    BFM Solutions GmbH<br />
                    Neuheimstr 34b<br />
                    CH-8853 Lachen<br />
                    Switzerland<br />
                    <img src="/images/email.png" style="height: 19px" />
                  </p>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-4 col-xs-6 pl-md-9 pl-6">
              <div class="footer-widget">
                <p class="widget-title">{{ t("footer.legal") }}</p>
                <ul class="widget-links pl-0 list-unstyled">
                  <li>
                    <router-link :to="`/impressum`">{{
                      t("routes.impressum")
                    }}</router-link>

<!-- <router-link :to="`/${locale}/impressum`">{{
                      t("routes.impressum")
                    }}</router-link> -->
                  </li>
                  <li>
                    <router-link to="/privacypolicy">
                      {{ t("menu.privacyPolicy") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/cookiespolicy">
                      {{ t("menu.cookiesPolicy") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/termsandconditions">
                      {{ t("menu.termsConditions") }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from "vue-i18n";
import SocialMedia from "@/components/SocialMedia.vue";
import AosVue from "aos-vue";

export default {
  name: "LLFooter",
  components: { SocialMedia },
  setup() {
    
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

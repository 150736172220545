<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>{{ t("confirmEmail.title") }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content Area -->
  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>{{ t("confirmEmail.subTitle") }}</p>
          </div>
          <div class="license-area">
            {{ info }}
          </div>
          <div class="license-area">
            <h3>
              {{ t("confirmEmail.thanks") }}, {{ name }}!</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "ConfirmEmail",
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
  data() {
    return {
      info: null,
    };
  },
  methods: {
    async confirmEmail() {
      var code = this.$route.query.code;
      var stage = this.$route.query.stage;
      try {
        var url = "";
        if (stage === "dev") {
          url =
            "https://api-dev.llamapoints.com/api/v1/businesses/contactemail/" +
            code;
        } else {
          url =
            "https://api.llamapoints.com/api/v1/businesses/contactemail/" +
            code;
        }

        const response = await axios.post(url);
        const results = response.data.results;
        this.info = this.$t("confirmEmail.success");
        this.name = this.$route.query.name;
      } catch (err) {
        this.info = this.$t("confirmEmail.failed");

        if (err.response) {
          console.log("Serrver error:", err);
        } else if (err.request) {
          console.log("Network error:", err);
        } else {
          console.log("Client error:", err);
        }
        console.log();
      }
    },
  },
  mounted() {
    this.confirmEmail();
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <header
    class="site-header site-header--menu-center landing-10-menu site-header--absolute site-header--sticky"
  >
    <div class="container">
      <nav class="navbar site-navbar">
        <!-- Brand Logo-->
        <div class="brand-logo">
          <a href="/">
            <!-- light version logo (logo must be black)-->
            <img
              src="/images/logo/logo-black.png"
              alt=""
              class="light-version-logo"
            />
            <!-- Dark version logo (logo must be White)-->
            <img
              src="/images/logo/logo-white.png"
              alt=""
              class="dark-version-logo"
            />
          </a>
        </div>
        <div class="menu-block-wrapper">
          <div class="menu-overlay" ref="menu-overlay"></div>
          <nav class="menu-block" id="append-menu-header" ref="menu-block">
            <div class="mobile-menu-head">
              <div class="go-back">
                <font-awesome-icon :icon="['fas', 'angle-left']" />
              </div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="site-menu-main">
              <li>
                <a href="#" class="nav-link-item" v-on:click="navigateTo('/')">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="nav-link-item"
                  v-on:click="navigateTo('/faq')"
                >
                  FAQ
                </a>
              </li>
              <li class="nav-item nav-item-has-children">
                <a class="nav-link-item drop-trigger">
                  {{ t("menu.howTo") }}
                  <font-awesome-icon :icon="['fas', 'angle-down']" />
                </a>
                <ul class="sub-menu" id="submenu-9">
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtosetupmanageddevice')"
                    >
                      {{ t("howToSetupManagedDevice.title") }}</a
                    >
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howToSetupStaff')"
                      >{{ t("howToSetupStaff.title") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtoprintmarketingmaterial')"
                    >
                      {{ t("howToPrintMarketing.title") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtoredeem')"
                    >
                      {{ t("howToRedeem.title") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtoscan')"
                    >
                      {{ t("howToScan.title") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtostaffhelper')"
                    >
                      {{ t("howToStaffHelper.title") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/howtowhatisanonymous')"
                    >
                      {{ t("howToWhatIsAnonymous.title") }}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="https://llamapoints.com/blog"
                  target="_blank"
                  class="nav-link-item"
                >
                  Blog
                </a>
              </li>

              <li class="nav-item nav-item-has-children">
                <a class="nav-link-item drop-trigger"
                  >{{ t("menu.legal") }}
                  <font-awesome-icon :icon="['fas', 'angle-down']" />
                </a>
                <ul class="sub-menu" id="submenu-9">
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/impressum')"
                    >
                      {{ t("menu.impressum") }}</a
                    >
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/privacypolicy')"
                      >{{ t("menu.privacyPolicy") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/cookiespolicy')"
                    >
                      {{ t("menu.cookiesPolicy") }}
                    </a>
                  </li>
                  <li class="sub-menu--item">
                    <a
                      href="#"
                      class="nav-link-item"
                      v-on:click="navigateTo('/termsandconditions')"
                    >
                      {{ t("menu.termsConditions") }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <language-picker />
        </div>

        <!-- mobile menu trigger -->
        <div class="mobile-menu-trigger">
          <span></span>
        </div>
        <!--/.Mobile Menu Hamburger Ends-->
      </nav>
    </div>
  </header>
</template>

<script>
import { useI18n } from "vue-i18n";
import LanguagePicker from "./LanguagePicker.vue";
export default {
  components: { LanguagePicker },
  name: "LLMenu",
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    toggleMenu() {
      const menu = document.querySelector(".menu-block");

      menu?.classList.toggle("active");
      document.querySelector(".menu-overlay")?.classList.toggle("active");
    },
    navigateTo(path) {
      this.$router.push(path);

      this.toggleMenu();
    },
  },
  mounted() {
    const menu = document.querySelector(".menu-block");
    const menuMain = menu?.querySelector(".site-menu-main");
    const submenuAll = menu?.querySelectorAll(".sub-menu");
    const goBack = menu?.querySelector(".go-back");
    const menuTrigger = document.querySelector(".mobile-menu-trigger");
    const closeMenu = menu?.querySelector(".mobile-menu-close");

    let subMenu;
    let subMenuArray = [];
    let subMenuTextArray = [];

    function last(array) {
      return array[array.length - 1];
    }
    function last2(array) {
      return array[array.length - 2];
    }

    menuMain?.addEventListener("click", (e) => {
      if (!menu?.classList.contains("active")) {
        return;
      }
      if (e?.target.closest(".nav-item-has-children")) {
        const hasChildren = e.target.closest(".nav-item-has-children");

        showSubMenu(hasChildren);
      }
    });

    goBack?.addEventListener("click", () => {
      const lastItem = last(subMenuArray);
      const lastItemText = last2(subMenuTextArray);
      subMenuArray.pop();
      subMenuTextArray.pop();
      if (subMenuArray.length >= 0) {
        document.getElementById(lastItem).style.animation =
          "slideRight 0.5s ease forwards";
        menu.querySelector(".current-menu-title").innerHTML = lastItemText;
        setTimeout(() => {
          document.getElementById(lastItem).classList.remove("active");
        }, 300);
      }
      if (subMenuArray.length == 0) {
        menu.querySelector(".mobile-menu-head").classList.remove("active");
      }
    });

    menuTrigger.addEventListener("click", () => {
      toggleMenu();
    });

    closeMenu.addEventListener("click", () => {
      toggleMenu();
    });

    document.querySelector(".menu-overlay").addEventListener("click", () => {
      toggleMenu();
    });

    function toggleMenu() {
      menu.classList.toggle("active");
      document.querySelector(".menu-overlay").classList.toggle("active");
    }

    function showSubMenu(hasChildren) {
      for (let i = 0; i < submenuAll.length; i++) {
        submenuAll[i].classList.remove("active");
      }
      subMenu = hasChildren.querySelector(".sub-menu");
      subMenuArray.push(subMenu.id);
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle = hasChildren.querySelector(".drop-trigger").textContent;
      subMenuTextArray.push(menuTitle);

      menu.querySelector(".current-menu-title").innerHTML = menuTitle;
      menu.querySelector(".mobile-menu-head").classList.add("active");
    }

    window.onresize = function () {
      if (this.innerWidth > 991) {
        if (menu.classList.contains("active")) {
          toggleMenu();
        }
      }
    };

    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        const sel1 = document.querySelector(".site-header--sticky");
        sel1.classList.add("scrolling");
      } else {
        const sel2 = document.querySelector(".site-header--sticky");
        sel2.classList.remove("scrolling");
      }
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        const sel3 = document.querySelector(".site-header--sticky.scrolling");
        sel3.classList.add("reveal-header");
      } else {
        const sel4 = document.querySelector(".site-header--sticky.scrolling");
        if (sel4 !== null) {
          sel4.classList.remove("reveal-header");
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <HomeContent />
</template>

<script >
import HomeContent from "@/components/HomeContent.vue";

export default {
  //inject: { cookieConsent: "cookieConsent" },
  name: "llama-home",
  components: {
    HomeContent,
  },
};
</script>

 

<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner position-relative">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>{{ t("howToSetupStaff.title") }}*</h2>
            <p>{{ t("howToSetupStaff.subTitle1") }}</p>
            <p>{{ t("howToSetupStaff.subTitle2") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content Area -->
  <div class="blog-details-content-area">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-xl-7 col-lg-8 col-md-12 order-lg-1 order-1">
          <div class="content">
            <h4>{{ t("howToSetupStaff.step1Title") }}</h4>
            <ol>
              <li>{{ t("howToSetupStaff.steps1.1") }}</li>
              <li>{{ t("howToSetupStaff.steps1.2") }}</li>
              <li>{{ t("howToSetupStaff.steps1.3") }}</li>
              <li>{{ t("howToSetupStaff.steps1.4") }}</li>
              <li>{{ t("howToSetupStaff.steps1.5") }}</li>
              <li>{{ t("howToSetupStaff.steps1.6") }}</li>
              <li>{{ t("howToSetupStaff.steps1.7") }}</li>
              <li>{{ t("howToSetupStaff.steps1.8") }}</li>
              <li>{{ t("howToSetupStaff.steps1.9") }}</li>
            </ol>
            <h4>{{ t("howToSetupStaff.step2Title") }}</h4>
            <ol>
              <li>{{ t("howToSetupStaff.steps2.1") }}</li>
              <li>{{ t("howToSetupStaff.steps2.2") }}</li>
              <li>{{ t("howToSetupStaff.steps2.3") }}</li>
              <li>{{ t("howToSetupStaff.steps2.4") }}</li>
            </ol>
            {{ t("howToSetupStaff.asterisk") }}
          </div>
        </div>

        <div class="offset-xl-1 col-xl-4 col-lg-4 col-md-10 order-lg-1 order-0">
          <div class="blog-details-right-sidebar">
            <div class="sidebar-bg recent-posts">
              <h4>{{ t("howToPages.watchVideoTutorial") }}</h4>
              <div class="recent-post-item">
                <div class="post-content">
                  <div class="post-title">
                    <h5>{{ t("howToPages.scanTheQrCode") }}</h5>
                  </div>

                  <div class="row text-center">
                    <p>
                      <vue-qrcode
                        :value="t('howToSetupStaff.tutorialUrl')"
                        :options="{ width: 200 }"
                      ></vue-qrcode>
                    </p>
                    <a :href="t('howToSetupStaff.tutorialUrl')" target="_blank">{{
                      t("howToPages.watchVideoTutorial")
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowToGetIt />
    </div>
  </div>
</template>
  
  <script>
import { defineComponent } from "vue";
import HowToGetIt from "@/components/HowToGetIt.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "HowToSetupStaff",
  components: { HowToGetIt, VueQrcode },
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
});
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
</style> 
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faAngleDown, faAngleLeft, faCircle, faPlay, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret, faAngleDown, faAngleLeft, faCircle, faPlay, faCheck, faTimes)

import AosVue from "aos-vue";

// Localization
// import enUS from './locales/en-US.json'
// import ja from './locales/ja-JP.json'
// import de from './locales/de-DE.json'

// import cookieConsent from 'bootstrap-cookie-consent-settings'; //https://www.npmjs.com/package/bootstrap-cookie-consent-settings

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(AosVue)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')

  function trackEvent(){

  }
<template>
  <!-- Content Area-2  -->
  <div class="content-area-l-12-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9">
          <div class="section-heading-6 text-center">
            <h2>
              {{ t("solution.title") }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div
          class="offset-xl-1 col-xl-5 col-md-7"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <div class="content-img position-relative">
            <div class="main-img">
              <div class="hero-video-l12 position-relative text-center">
                <!-- <VideoPlayer /> -->
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/BPET8VcPFCc?si=yjGpOE7TU9Y9F401"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <div class="d-inline-block video-icon d-inline-block">
                  <i class="fas fa-play align-center"></i>
                </div>
              </div>
            </div>

            <div class="content-area-l-12-shape d-none d-md-block">
              <img src="images/l6/shape-6.svg" alt="" class="w-100" />
            </div>
          </div>
        </div>
        <div
          class="offset-xxl-1 col-xxl-4 col-xl-5 col-lg-7 col-md-9"
          data-aos="fade-left"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <div class="content section-heading-6">
            <h3>{{ t("solution.section1Header") }}</h3>
            <p>
              <strong>{{ t("solution.section1Body") }}</strong>
            </p>
            <br />

            <h4>{{ t("solution.section2Header") }}</h4>
            <p>{{ t("solution.section2Body") }}</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { useI18n } from "vue-i18n";
// import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "llama-solution",
  components: {
    // VideoPlayer,
  },
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
</style>
  
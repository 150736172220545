export default {
  en: {
    currencyFormat: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol"
    }
  },
  de: {
    currencyFormat: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol"
    }
  },
  hr: {
    currencyFormat: {
      style: "currency",
      currency: "HRK"
    }
  }
};

<template>
  <div class="site-wrapper overflow-hidden position-relative">
    <!-- Hero Area -->
    <div class="hero-area-l-12 position-relative z-index-1 overflow-hidden">
      <div class="container">
        <div class="row justify-content-center">
          <aos-vue
            class="col-xl-6 col-lg-7 col-md-8 col-sm-12 order-lg-1 order-1"
            animation="fade-right"
            :duration="800"
            :data-aos-once="true"
          >
            <div class="content">
              <h2>{{ t("homePage.header") }}</h2>
              <p>{{ t("homePage.tagLineSub") }}</p>
              
              <!-- Content How to get it -->
              <HowToGetIt />
              
            </div>
          </aos-vue>
          <aos-vue
            class="col-xl-6 col-lg-5 col-md-8 order-lg-1 order-0"
            animation="fade-left"
            :duration="800"
            :once="true"
          >
            <div class="hero-video-l12 position-relative text-center">
              <img src="images/happy-customer-girls-3.jpg" alt="" />
            </div>
            <!-- <div class="hero-video-l12 position-relative text-center">
              <VideoPlayer />
              <div class="d-inline-block video-icon d-inline-block">
                <i class="fas fa-play align-center"></i>
              </div>
            </div> -->
          </aos-vue>
        </div>
      </div>
      <div class="hero-shape-llama d-none d-sm-block">
        <img src="images/logo/llama.svg" alt="" />
      </div>
      <div class="hero-shape-l12-1 d-none d-sm-block">
        <img src="images/l6/shape-1.png" alt="" />
      </div>
      <div class="hero-shape-l12-2 d-none d-sm-block">
        <img src="images/l6/shape-3.1.png" alt="" />
      </div>
    </div>

    <!-- Section Solution -->
    <llamasolution />

    <!-- Section Benefits -->
    <llamabenefits />

    <!-- Section Price table Area -->
    <PricingTable />
    <HowToGetIt />
  </div>
</template>

<script >
import { useI18n } from "vue-i18n";
//import VideoPlayer from "@/components/VideoPlayer.vue";
import llamasolution from "@/components/Solution.vue";
import llamabenefits from "@/components/Benefits.vue";
import HowToGetIt from "@/components/HowToGetIt.vue";
import PricingTable from "@/components/PricingTable.vue";

export default {
  name: "HomeContent",
  //components: { VideoPlayer, llamabenefits, HowToGetIt, PricingTable },
  components: { llamasolution, llamabenefits, HowToGetIt, PricingTable },
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>

<style scoped>
</style>

<template>

    <!-- Feature Area -->
    <div class="feature-area-price-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading-14 text-center">
                        <h2>{{ t("homePage.followUs") }}</h2>
                    </div>
                </div>
            </div>
            <div class="row feature-area-price-1-items">
                <!-- <aos-vue animation="slide-up">
                    <p>Hello World</p>
                </aos-vue> -->
                <aos-vue animation="fade-right" class="col-lg-4 col-md-6" :delay="500" :duration="1000">
                    <div class="feature-card-price-1">
                        <a href="https://www.facebook.com/profile.php?id=100088278831095&sk=about" target="_blank">
                            <div class="d-flex align-items-center">
                                <div class="icon">
                                    <img src="/images/facebook.png" alt="Facebook">
                                </div>
                                <div class="content">
                                    <h4>Facebook</h4>
                                    <p></p>
                                </div>
                            </div>
                        </a>
                    </div>
                </aos-vue>
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000">
                    <div class="feature-card-price-1">
                        <a href="https://www.instagram.com/llamapoints/" target="_blank">
                            <div class="d-flex align-items-center">
                                <div class="icon icon-2">
                                    <img src="/images/instagram.png" alt="Instagram">
                                </div>
                                <div class="content">
                                    <h4>Instagram</h4>
                                    <p></p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-aos="fade-left" data-aos-delay="900" data-aos-duration="1000">
                    <div class="feature-card-price-1">
                        <a href="https://www.youtube.com/@LlamaPoints" target="_blank">
                            <div class="d-flex align-items-center">
                                <div class="icon icon-3">
                                    <img src="/images/youtube.png" alt="YouTube">
                                </div>
                                <div class="content">
                                    <h4>YouTube</h4>
                                    <p></p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    name: "SocialMedia",
    setup() {
        const { t, locale } = useI18n();

        return {
            locale,
            t,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<template>
  <div class="image-container">
    <img src="/images/qr-code.png" alt="Llamapoints QrCode">
  </div>
</template>

<script>
export default {
  name: 'LlamaQrCode',
};
</script>

<style scoped>
.image-container {
    /* Flexbox properties to center the image */
    display: flex;
  justify-content: center;
  /* Ensure the container itself can expand as needed */
  width: 100%;
  height: auto;
}

img {
  /* Make the image fill the width of the container */
  width: 100%;
  height: auto;
  display: block; /* Remove bottom whitespace */
  max-width: 550px; /* Set the maximum width */
  max-height: 550px; /* Set the maximum height (optional) */
  margin: 20px; /* Add margin around the image */
}
</style>
<template>
  <div class="topics-area-l-12 position-relative">
    <div class="topics-area-l12-shape-1">
      <img src="images/l6/shape-4.1.svg" alt="" class="w-100" />
    </div>
    <div class="topics-area-l12-shape-2">
      <img src="images/l6/shape-5.svg" alt="" class="w-100" />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9">
          <div class="section-heading-6 text-center">
            <h2>{{ t("benefits.title") }}</h2>
            <p>
              {{ t("benefits.subTitle") }}
            </p>
            <p>
              {{ t("benefits.subTitle2") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <aos-vue
          class="col-12 aos-init aos-animate"
          animation="fade-left"
          :duration="800"
          :once="true"
        >
          <div class="faq-content-l-12">
            <div class="accordion" id="accordion1">
              <!-- Benefit 1 - Single accordion Start -->
              <aos-vue
                class="topics-accordion-wrapper overflow-hidden"
                animation="fade-right"
                :duration="800"
                :once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row justify-content-lg-center w-100">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>{{ t("benefits.benefit1") }}</h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
              </aos-vue>
              <!-- Single accordion end -->
              <!-- Benefit 2 - Single accordion Start -->
              <div
                class="topics-accordion-wrapper overflow-hidden"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row w-100 justify-content-lg-center">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>{{ t("benefits.benefit2") }}</h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
                <div id="collapse1-2" class="collapse show">
                  <div
                    class="row justify-content-lg-start justify-content-center"
                  >
                    <div
                      class="col-xl-9 offset-xl-1 col-lg-10 col-sm-8 offset-lg-0"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- Single accordion end -->
              <!-- Benefit 3 - Single accordion Start -->
              <aos-vue
                class="topics-accordion-wrapper overflow-hidden"
                animation="fade-right"
                :duration="800"
                :once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row justify-content-lg-center w-100">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>
                            {{ t("benefits.benefit3") }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
                <div id="collapse1-3" class="collapse">
                  <div
                    class="row justify-content-lg-start justify-content-center"
                  >
                    <div
                      class="col-xl-9 offset-xl-1 col-lg-10 col-sm-8 offset-lg-0"
                    ></div>
                  </div>
                </div>
              </aos-vue>
              <!-- Single accordion end -->
              <!-- Benefit 4 - Single accordion Start -->
              <!-- <aos-vue
                class="topics-accordion-wrapper overflow-hidden"
                animation="fade-right"
                :duration="800"
                :once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row justify-content-lg-center w-100">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>
                            {{ t("benefits.benefit4") }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
                <div id="collapse1-4" class="collapse">
                  <div
                    class="row justify-content-lg-start justify-content-center"
                  >
                    <div
                      class="col-xl-9 offset-xl-1 col-lg-10 col-sm-8 offset-lg-0"
                    ></div>
                  </div>
                </div>
              </aos-vue> -->
              <!-- Single accordion end -->
              <!-- Benefit 5 - Single accordion Start -->
              <!-- <aos-vue
                class="topics-accordion-wrapper overflow-hidden"
                animation="fade-right"
                :duration="800"
                :once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row justify-content-lg-center w-100">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>
                            {{ t("benefits.benefit5") }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
                <div id="collapse1-3" class="collapse">
                  <div
                    class="row justify-content-lg-start justify-content-center"
                  >
                    <div
                      class="col-xl-9 offset-xl-1 col-lg-10 col-sm-8 offset-lg-0"
                    ></div>
                  </div>
                </div>
              </aos-vue> -->
              <!-- Single accordion end -->
              <!-- Benefit 6 - Single accordion Start -->
              <!-- <aos-vue
                class="topics-accordion-wrapper overflow-hidden"
                animation="fade-right"
                :duration="800"
                :once="true"
              >
                <div class="mb-0 border-bottom-0">
                  <div class="row justify-content-lg-center w-100">
                    <div class="col-xxl-5 col-xl-10 col-lg-7 col-md-10">
                      <div class="d-xs-flex">
                        <div class="topics-main-icon">
                          <img src="images/l6/heart1.svg" alt="" />
                        </div>
                        <div class="content">
                          <h3>
                            {{ t("benefits.benefit6") }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-7 col-xl-6 offset-xl-0 col-lg-5 offset-lg-0 col-md-8 offset-md-2 col-sm-8 offset-sm-2 text-right"
                    >
                      <div class="d-flex justify-content-lg-end"></div>
                    </div>
                  </div>
                </div>
                <div id="collapse1-3" class="collapse">
                  <div
                    class="row justify-content-lg-start justify-content-center"
                  >
                    <div
                      class="col-xl-9 offset-xl-1 col-lg-10 col-sm-8 offset-lg-0"
                    ></div>
                  </div>
                </div>
              </aos-vue> -->
              <!-- Single accordion end -->
            </div>
          </div>
        </aos-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "llama-benefits",
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>Cookie Policy</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content Area -->
  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>
              This cookie policy (“Policy”) describes what cookies are and how
              and they're being used by the llamapoints.com website (“Website”
              or “Service”) and any of its related products and services
              (collectively, “Services”). This Policy is a legally binding
              agreement between you (“User”, “you” or “your”) and BFM Solutions
              GmbH (doing business as “Llama Points”, “we”, “us” or “our”). If
              you are entering into this agreement on behalf of a business or
              other legal entity, you represent that you have the authority to
              bind such entity to this agreement, in which case the terms
              “User”, “you” or “your” shall refer to such entity. If you do not
              have such authority, or if you do not agree with the terms of this
              agreement, you must not accept this agreement and may not access
              and use the Website and Services. You should read this Policy so
              you can understand the types of cookies we use, the information we
              collect using cookies and how that information is used. It also
              describes the choices available to you regarding accepting or
              declining the use of cookies.
            </p>
          </div>
          <div class="license-area">
            <h3>What are cookies?</h3>
            <p>
              Cookies are small pieces of data stored in text files that are
              saved on your computer or other devices when websites are loaded
              in a browser. They are widely used to remember you and your
              preferences, either for a single visit (through a "session
              cookie") or for multiple repeat visits (using a "persistent
              cookie").
            </p>
            <p>
              Session cookies are temporary cookies that are used during the
              course of your visit to the Website, and they expire when you
              close the web browser.
            </p>
            <p>
              Persistent cookies are used to remember your preferences within
              our Website and remain on your desktop or mobile device even after
              you close your browser or restart your computer. They ensure a
              consistent and efficient experience for you while visiting the
              Website and Services.
            </p>
            <p>
              Cookies may be set by the Website ("first-party cookies"), or by
              third parties, such as those who serve content or provide
              advertising or analytics services on the Website ("third party
              cookies"). These third parties can recognize you when you visit
              our website and also when you visit certain other websites. You
              may learn more about cookies and how they work in this guide.
            </p>
          </div>
          <div class="license-area">
            <h3>What type of cookies do we use?</h3>
            <h4>Functionality cookies</h4>
            <p>
              Functionality cookies let us operate the Website and Services in
              accordance with the choices you make. For example, we will
              recognize your username and remember how you customized the
              Website and Services during future visits.
            </p>

            <h4>Analytical cookies</h4>
            <p>
              These cookies enable us and third party services to collect
              aggregated data for statistical purposes on how our visitors use
              the Website. These cookies do not contain personal information
              such as names and email addresses and are used to help us improve
              your user experience of the Website.
            </p>
            <h4>Social media cookies</h4>
            <p>
              Third party cookies from social media sites (such as Facebook,
              Twitter, etc) let us track social network users when they visit or
              use the Website and Services, or share content, by using a tagging
              mechanism provided by those social networks.
            </p>
            <p>
              These cookies are also used for event tracking and remarketing
              purposes. Any data collected with these tags will be used in
              accordance with our and social networks’ privacy policies. We will
              not collect or share any personally identifiable information from
              the user.
            </p>
          </div>
          <div class="license-area">
            <h3>Do we use web beacons or tracking pixels?</h3>
            <p>
              Our emails may contain a "web beacon" (or "tracking pixel") to
              tell us whether our emails are opened and verify any clicks
              through to links or advertisements within the email.
            </p>
            <p>
              We may use this information for purposes including determining
              which of our emails are more interesting to users and to query
              whether users who do not open our emails wish to continue
              receiving them.
            </p>
            <p>
              The pixel will be deleted when you delete the email. If you do not
              wish the pixel to be downloaded to your device, you should read
              the email in plain text view or with images disabled.
            </p>
          </div>
          <div class="license-area">
            <h3>What are your cookie options?</h3>
            <p>
              If you don’t like the idea of cookies or certain types of cookies,
              you can change your browser’s settings to delete cookies that have
              already been set and to not accept new cookies. To learn more
              about how to do this, visit
              <a
                target="_blank"
                href="https://www.internetcookies.com"
                rel="noopener"
                >internetcookies.com</a
              >
            </p>
          </div>
          <div class="license-area">
            <h3>Changes and amendments</h3>
            <p>
              We reserve the right to modify this Policy or its terms related to
              the Website and Services at any time at our discretion. When we
              do, we will revise the updated date at the bottom of this page. We
              may also provide notice to you in other ways at our discretion,
              such as through the contact information you have provided.
            </p>
            <p>
              An updated version of this Policy will be effective immediately
              upon the posting of the revised Policy unless otherwise specified.
              Your continued use of the Website and Services after the effective
              date of the revised Policy (or such other act specified at that
              time) will constitute your consent to those changes.
            </p>
          </div>
          <div class="license-area">
            <h3>Acceptance of this policy</h3>
            <p>
              You acknowledge that you have read this Policy and agree to all
              its terms and conditions. By accessing and using the Website and
              Services you agree to be bound by this Policy. If you do not agree
              to abide by the terms of this Policy, you are not authorized to
              access or use the Website and Services.
            </p>
          </div>
          <div class="license-area">
            <h3>Contacting us</h3>
            <p>
              If you have any questions, concerns, or complaints regarding this
              Policy or the use of cookies, we encourage you to contact us using
              the details below:
            </p>
            <p><img src="/images/email.png" style="height: 22px" /></p>
          </div>
          <p>This document was last updated on August 29, 2021</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CookiesPolicy",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
